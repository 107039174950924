import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import topImage from '../images/contact.png';
import Img from "gatsby-image";
import { clearConfigCache } from "prettier";
import { mergeClasses } from "@material-ui/styles";
import TextField from '@material-ui/core/TextField';
import firebase from "gatsby-plugin-firebase";
// import { withFirebase } from "../components/WithFirebase";
import {navigate} from 'gatsby';

const flag = typeof window !== 'undefined';

// let auth = null;
let functions = null;
let jQuery;
if (flag) {
    require("firebase/auth");
    require("firebase/firestore");
    require("firebase/functions");
    functions = firebase.functions();
    jQuery = require('jquery');
    // auth = firebase.auth();
}

const requiredValue = [
    'name',
    'content'
];

const notString = [
    'disabled',
    'seo',
    'formDisabled'
];

const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 650,
            md: 1333,
            lg: 1920,
            xl: 2000,
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#fff'
            }
        }
    }
});

const styles = theme => ({
    divider: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
    },
    root: {
        fontWeight: "bold",
        // backgroundColor: '#000'
    },
    'input': {
        '&::placeholder': {
            color: '#fff',
            fontWeight: 900
        }
    }
    // gatsbyImageWrapper: {
    //   [theme.breakpoints.down('sm')]: {
    //     width: 'calc(100vw / 4)',
    //     height: 'calc(30vw / 6)'
    //   },
    //   [theme.breakpoints.down('xs')]: {
    //     width: 0,
    //     height: 0
    //   }
    // }
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

const insertUserDatas = async (insertData, state) => {
    // await firebaseDb.ref('/clients/' + uid).set(insertData);
    // state.setState({formDisabled: false})
    // console.log(state.state.formDisabled)
    // console.log(firebase.functions())
    // console.log(state.props.firebase.functions().httpsCallable('insertBigquery'))
    const insertBigquery = await functions.httpsCallable('insertBigquery');
    await insertBigquery(insertData);
    alert('ご入力ありがとうございました。')
    navigate('/');
}

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            seo: false,
            formDisabled: false,
            name: '',
            phone: '',
            mail: '',
            company: '',
            content: '',
            detail: ''
        }

        var url = this.props.location.href
        if (typeof(url) === 'string') {
            if (url.indexOf('staging') !== -1) {
                // console.log(this.state.disabled)
                this.state.disabled = true
                // console.log(this.state.disabled)
            } else {
                this.state.seo = true
            }
            // console.log(this.state.seo)
        }
        // console.log(this.props.data.testFixed)
        // authIP(this)

        this.handleChange = this.handleChange.bind(this);
        this.delSpace = this.delSpace.bind(this);
        this.addSpace = this.addSpace.bind(this);
        this.handleOnSubmit = this.handleOnSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.state.disabled) {
            var res = await fetch('/api/authIP', {
                method: 'POST'
            }).then((response) => {
                // console.log(response)
                return response.text();
            });
            // console.log(res)
            if (res === 'Y') {
                // console.log(state.state.disabled)
                this.setState({disabled: false});
            }
        // console.log(state.state.disabled)
        }
        // console.log(jQuery('div.gatsby-image-wrapper'))
        jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
        jQuery('div.gatsby-image-wrapper').css('width', '');
        jQuery('div.gatsby-image-wrapper').css('position', '');
    }

    renderImage(image) {
    // console.log(image.node.relativePath.split('/')[1])
        return (
            <table>
                <tr className='image-tr'>
                    <Img
                        fixed={image.node.childImageSharp.fixed}
                        alt={image.node.relativePath.split('/')[1].split('.')[0]}
                        style={{display: 'table-cell', width: '100%', height: 'auto'}}
                    />
                </tr>
                <tr>
                    <h2>{image.node.relativePath.split('/')[1].split('.')[0]}</h2>
                </tr>
            </table>
        );
    }

    renderSeo() {
        return (
            <Seo title="Contact">
                <meta
                    name="description"
                    content="AristoBrain株式会社へのお問い合わせはこちらから。お気軽にどうぞ。"
                />
                <meta
                    name="keywords"
                    content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,お問い合わせ"
                />
            </Seo>
        )
    }

    handleChange(event) {
        var insertName = {};
        insertName[event.target.name] = event.target.value;
        if (event.target.value.replace(' ', '').replace('　', '').length > 0) {
            if (event.target.name === 'phone' && this.state['mail'].replace(' ', '').replace('　', '').length === 0) {
                insertName['mail'] = ' ';
            } else if (event.target.name === 'mail' && this.state['phone'].length === 0) {
                insertName['phone'] = ' ';
            }
        } else {
            insertName[event.target.name] = event.target.value.replace(' ', '').replace('　', '');
            if (event.target.name === 'phone' && this.state['mail'].replace(' ', '').replace('　', '').length === 0) {
                insertName['mail'] = '';
                insertName['phone'] = '';
            } else if (event.target.name === 'mail' && this.state['phone'].replace(' ', '').replace('　', '').length === 0) {
                insertName['phone'] = '';
                insertName['mail'] = '';
            }
        }
        this.setState(insertName);
        // console.log(this.state);
    }

    delSpace(event) {
        var insertName = {};
        insertName[event.target.name] = this.state[event.target.name].replace(' ', '').replace('　', '');
        this.setState(insertName);
        // console.log(this.state[event.target.name]);
    }

    addSpace(event) {
        var insertName = {};
        if (event.target.name === 'phone' && event.target.value.replace(' ', '').replace('　', '').length === 0 && this.state['mail'].replace(' ', '').replace('　', '').length > 0) {
            insertName['phone'] = ' '
        } else if (event.target.name === 'mail' && event.target.value.replace(' ', '').replace('　', '').length === 0 && this.state['phone'].replace(' ', '').replace('　', '').length > 0) {
            insertName['mail'] = ' '
        }
        this.setState(insertName);
        // console.log(this.state[event.target.name]);
    }

    //登録ボタンが押されたら
    handleOnSubmit(event) {
        this.setState({formDisabled: true});
        var flag = true;
        for(var i=0; i < requiredValue.length; i++) {
            // console.log(this.state[requiredValue[i]]);
            if (this.state[requiredValue[i]].length === 0) {//|| (requiredValue[i] === 'birthday' && !this.checkDateVaridate(this.state[requiredValue[i]]))) {
                flag = false;
                break;
            }
        };

        if (this.state.mail.replace(' ', '').replace('　', '').length + this.state.phone.replace(' ', '').replace('　', '').length === 0) {
            flag = false;
        }

        // console.log(flag)

        if (flag) {
            // document.getElementById("submitButton").formDisabled = true;
            //console.log(document.getElementById("submitButton"))
            var insertData = {}
            for (let key in this.state) {
                // console.log(key)
                // console.log(notString.indexOf(key))
                if (notString.indexOf(key) === -1) {
                    if (this.state[key].replace(' ', '').length === 0) {
                        insertData[key] = '記載なし'
                    } else {
                        insertData[key] = this.state[key]
                    }
                }
            };

            // console.log(insertData)
            insertUserDatas(insertData, this)
        } else {
            alert('適切に記入されていない必須項目があります。')
            this.setState({formDisabled: false})
        }
    };


  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      // console.log(theme.gatsbyImageWrapper)
      // console.log(this.props.classes.gatsbyImageWrapper)
      return (
        <Page url={this.props.location.pathname}>
            <ThemeProvider theme={theme}>
                {this.state.seo && this.renderSeo()}
                <div className='fade-in-news-image' style={{backgroundImage: `url(${topImage})`, backgroundSize: 'cover', backgroundPosition: 'center', display: 'flow-root', padding: '0 5% 10% 0'}}>
                    <div style={{margin: 0, padding: 0}}>
                        <div className='under-bar-title'>
                        <h1 className='fade-in-news-title'>Contact</h1>
                        <p className='fade-in-about-text'>
                            お問い合わせ
                        </p>
                        </div>
                        <div className='under-bar-text fade-in-news-button'>
                            <p>
                                お仕事のご依頼、採用やインターンシップの応募など弊社へのお問い合わせは、以下のフォームまたはメール( contact@aristobrain.com )にてお願いいたします。
                            </p>
                            <Link to='/privacy/'><p style={{textAlign: 'right'}}>プライバシーポリシー</p></Link>
                            <TextField 
                                disabled={this.state.formDisabled} 
                                placeholder="必須" 
                                fullWidth　
                                label='ご氏名（Name）' 
                                name='name' 
                                value={this.state.name} 
                                variant="outlined" 
                                onChange={this.handleChange} 
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                required 
                                className='text-form'
                            />
                            {/* {this.state.name === "" && <p className='error-message'>ご氏名は必須です。</p>} */}
                            <TextField 
                                disabled={this.state.formDisabled} 
                                placeholder="電話・メールの両方かどちらか" 
                                fullWidth　
                                label='お電話番号（Tel Number）' 
                                name='phone'
                                value={this.state.phone} 
                                variant="outlined" 
                                onChange={this.handleChange} 
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                required 
                                className='text-form'
                                onClick={this.delSpace}
                                onMouseOut={this.addSpace}
                            />
                            <TextField 
                                disabled={this.state.formDisabled} 
                                placeholder="電話・メールの両方かどちらか" 
                                fullWidth　
                                label='メールアドレス（E-mail Address）' 
                                name='mail'
                                value={this.state.mail} 
                                variant="outlined" 
                                onChange={this.handleChange} 
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                required
                                className='text-form'
                                onClick={this.delSpace}
                                onMouseOut={this.addSpace}
                            />
                            {/* {(this.state.phone === "" && this.state.mail === "") && <p className='error-message'>連絡先は必須です。</p>} */}
                            <TextField 
                                disabled={this.state.formDisabled} 
                                fullWidth　
                                label='会社名（Company Name）' 
                                name='company' 
                                value={this.state.company} 
                                variant="outlined" 
                                onChange={this.handleChange} 
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                className='text-form'
                            />
                            <TextField 
                                disabled={this.state.formDisabled} 
                                placeholder="必須" 
                                fullWidth　
                                label='件名（Content）' 
                                name='content' 
                                value={this.state.content} 
                                variant="outlined" 
                                onChange={this.handleChange} 
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                required 
                                className='text-form'
                            />
                            {/* {this.state.content === "" && <p className='error-message'>件名は必須です。</p>} */}
                            <TextField 
                                disabled={this.state.formDisabled} 
                                fullWidth　
                                label='お問い合わせ内容（Detail）' 
                                name='detail' 
                                value={this.state.detail} 
                                variant="outlined" 
                                onChange={this.handleChange}
                                InputProps={{ classes: {input: this.props.classes['input']} }} 
                                InputLabelProps={{ shrink: true }} 
                                className='text-form'
                                multiline
                                rows={5}
                                style={{marginBottom: '5%'}}
                            />
                            <Button
                                variant="outlined"
                                className='skallBottun'
                                size="large"
                                color='primary'
                                style={{float: 'right'}}
                                onClick={this.handleOnSubmit}
                                disabled={this.state.formDisabled}
                            >
                                送信（ send ）
                            </Button>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        </Page>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(Contact));